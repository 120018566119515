<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog
        v-model="editPresenceDialog"
        persistent
        max-width="900"
        v-if="presence && presence.member"
      >
        <v-card>
          <v-toolbar color="primary" dark>
            <v-btn icon dark @click="closePresenceDialog(true)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title> {{ presence.member.person.corporateName }}</v-toolbar-title>
          </v-toolbar>
          <v-container fluid>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4" class="mt-n4">
                  <v-text-field
                    outlined
                    v-model="presence.date"
                    type="date"
                    label="Data"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="5" class="mt-n4">
                  <v-text-field
                    outlined
                    v-model="presence.description"
                    label="Descrição"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="mt-n4">
                  <v-switch
                    @change="presence = changePresence(presence)"
                    small
                    v-model="presence.presenceVisitor"
                    label="Presente?"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="3" class="mt-n8">
                  <v-switch
                    :disabled="!presence.presenceVisitor"
                    @change="
                      presence.points = presence.withBible
                        ? presence.points + 5
                        : presence.points - 5
                    "
                    small
                    v-model="presence.withBible"
                    label="Com Bíblia?"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="3" class="mt-n8">
                  <v-switch
                    :disabled="!presence.presenceVisitor"
                    @change="
                      presence.points = presence.withVisitor
                        ? presence.points + 15
                        : presence.points - 15
                    "
                    small
                    v-model="presence.withVisitor"
                    label="Com visitante?"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="3" class="mt-n8">
                  <v-switch
                    :disabled="!presence.presenceVisitor"
                    @change="
                      presence.points = presence.completedChallenge
                        ? presence.points + 50
                        : presence.points - 50
                    "
                    small
                    v-model="presence.completedChallenge"
                    label="Cumpriu Desafio?"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="3" class="mt-n8">
                  <v-text-field
                    v-model="presence.points"
                    type="number"
                    label="Total de Pontos"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="closePresenceDialog(true)">
                CANCELAR
              </v-btn>
              <v-btn dark color="green" @click="updateOneItem"> SALVAR </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO Será
          Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Registro</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="presences"
          :search="search"
          loading-text="Buscando registros"
          no-data-text="Sem dados no momento"
          no-results-text="Sua busca não encontro registros"
          :header-props="headerProps"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span>{{ dateFormat(item.date) }}</span>
          </template>
          <template v-slot:[`item.presenceVisitor`]="{ item }">
            <v-switch small v-model="item.presenceVisitor" disabled></v-switch>
          </template>

          <template v-slot:[`item.withBible`]="{ item }">
            <v-switch small v-model="item.withBible" disabled></v-switch>
          </template>

          <template v-slot:[`item.withVisitor`]="{ item }">
            <v-switch small v-model="item.withVisitor" disabled></v-switch>
          </template>

          <template v-slot:[`item.completedChallenge`]="{ item }">
            <v-switch small v-model="item.completedChallenge" disabled></v-switch>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="openEditPresence(item)">mdi-pencil</v-icon>

            <v-icon small class="mr-2" @click="deleteOpen(item.id)">mdi-eraser</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog
        v-model="openDialog"
        persistent
        max-width="1200px"
        :fullscreen="fullScreen"
      >
        <v-card>
          <v-toolbar color="primary" dark flat style="padding-right: 10px !important">
            <v-toolbar-title class="overline">{{ title }}</v-toolbar-title>
            <v-btn icon right absolute dark>
              <v-icon @click="fullScreen = !fullScreen">{{ setIconScreen() }}</v-icon>
              <v-icon @click="closeDialog()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    type="date"
                    outlined
                    v-model="dateInput"
                    label="Data"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    outlined
                    v-model="description"
                    label="Descrição"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-data-table
                    :loading="loading"
                    :headers="headerPresence"
                    :items="dataPayload"
                    disable-pagination
                    disable-sort
                    disable-filtering
                    hide-default-footer
                  >
                    <template v-slot:[`item.presenceVisitor`]="{ item }">
                      <v-switch
                        @change="item = changePresence(item)"
                        small
                        v-model="item.presenceVisitor"
                      ></v-switch>
                    </template>
                    <template v-slot:[`item.withBible`]="{ item }">
                      <v-switch
                        :disabled="!item.presenceVisitor"
                        @change="
                          item.points = item.withBible ? item.points + 5 : item.points - 5
                        "
                        small
                        v-model="item.withBible"
                      ></v-switch>
                    </template>
                    <template v-slot:[`item.withVisitor`]="{ item }">
                      <v-switch
                        :disabled="!item.presenceVisitor"
                        @change="
                          item.points = item.withVisitor
                            ? item.points + 15
                            : item.points - 15
                        "
                        small
                        v-model="item.withVisitor"
                      ></v-switch>
                    </template>
                    <template v-slot:[`item.completedChallenge`]="{ item }">
                      <v-switch
                        :disabled="!item.presenceVisitor"
                        @change="
                          item.points = item.completedChallenge
                            ? item.points + 50
                            : item.points - 50
                        "
                        small
                        v-model="item.completedChallenge"
                      ></v-switch>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loadingAction"
                  width="200px"
                  color="#2ca01c"
                  dark
                  @click="insertAll()"
                >
                  Registrar Pontos
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";
import moment from "moment";

export default Vue.extend({
  name: "Points",
  components: { InternalMenu, PageHeader },
  data: () => ({
    title: "Registro de Presença",
    menuItems: [],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pontuação",
        disabled: true,
        href: "/presences",
      },
    ],
    deleteDialog: false,
    presences: [],
    members: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    id: "",
    dateInput: moment().format("yyyy-MM-DD"),
    description: "Presença EBD",
    openDialog: false,
    fullScreen: false,
    dataPayload: [
      {
        memberId: "",
        memberName: "",
        presenceVisitor: false,
        withBible: false,
        withVisitor: false,
        completedChallenge: false,
        points: 0,
      },
    ],
    presence: {
      id: null,
      memberId: null,
      memberName: "",
      presenceVisitor: true,
      withBible: false,
      withVisitor: false,
      completedChallenge: false,
      points: 10,
    },
    editPresenceDialog: false,
    headerProps: {
      sortByText: "Ordenar por",
    },
    networks: [],
    headers: [
      {
        text: "Data",
        align: "start",
        value: "date",
        filtering: true,
      },
      {
        text: "Nome",
        align: "start",
        value: "member.person.corporateName",
        filtering: true,
      },
      {
        text: "Presente?",
        align: "start",
        value: "presenceVisitor",
        filtering: true,
      },
      {
        text: "Com Bíblia?",
        align: "start",
        value: "withBible",
        filtering: true,
      },
      {
        text: "Com Visitante?",
        align: "start",
        value: "withVisitor",
        filtering: true,
      },
      {
        text: "Cumpriu Desafio?",
        align: "start",
        value: "completedChallenge",
        filtering: true,
      },
      {
        text: "Pontuação",
        align: "start",
        value: "points",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],

    headerPresence: [
      {
        text: "Nome",
        align: "start",
        value: "memberName",
        filtering: true,
      },
      {
        text: "Presente?",
        align: "start",
        value: "presenceVisitor",
        filtering: true,
      },
      {
        text: "Com Bíblia?",
        align: "start",
        value: "withBible",
        filtering: true,
      },
      {
        text: "Com Visitante?",
        align: "start",
        value: "withVisitor",
        filtering: true,
      },
      {
        text: "Cumpriu Desafio?",
        align: "start",
        value: "completedChallenge",
        filtering: true,
      },
      {
        text: "Pontos",
        align: "start",
        value: "points",
        filtering: true,
      },
    ],
  }),
  methods: {
    openEditPresence(item) {
      this.presence = item;
      this.editPresenceDialog = true;
    },
    changePresence(item) {
      if (!item.presenceVisitor) {
        item.withBible = false;
        item.withVisitor = false;
        item.completedChallenge = false;
        item.points = 0;
        return item;
      }
      item.points = item.points += 10;
      return item;
    },
    closePresenceDialog(reload = false) {
      this.presence = {
        id: null,
        memberId: null,
        memberName: "",
        presenceVisitor: false,
        withBible: false,
        withVisitor: false,
        completedChallenge: false,
        points: 0,
      };
      this.editPresenceDialog = false;
      if (reload) {
        this.getItems();
      }
    },
    updateOneItem() {
      if (!this.presence.id) {
        return;
      }
      this.loading = true;
      http.put(`promise/presences/${this.presence.id}`, this.presence).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Atualizado com Sucesso!";
          this.snackbar.opened = true;
          this.loading = false;
          this.closePresenceDialog();
          this.getItems();
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text = "Falha ao atualizar registro";
          this.snackbar.opened = true;
          this.loading = false;
        }
      );
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    dateFormat(referenceDate) {
      return moment(referenceDate).format("DD/MM/YYYY");
    },

    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    deleteItem() {
      http.delete(`promise/presences/${this.id}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getItems() {
      this.loading = true;
      http.get("promise/presences?sort=createdAt,DESC").then(
        (data) => {
          this.presences = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
    changePoints(item) {
      this.dataPayload.filter((a) => a.memberId != item.memberId);
    },

    getLeaders() {
      this.loading = true;
      http
        .get("gerentor/customers?filter=active||eq||true&sort=person_corporate_name,ASC")
        .then((res) => {
          const { data } = res;
          this.members = data;
          this.dataPayload = [];
          for (const member of data) {
            this.dataPayload.push({
              memberId: member.id,
              memberName: member.person.corporateName,
              withVisitor: false,
              withBible: false,
              completedChallenge: false,
              presenceVisitor: false,
              points: 0,
            });
          }
          this.loading = false;
        });
    },
    insertAll() {
      const items = [];
      const filtered = this.dataPayload.filter((a) => a.presenceVisitor == true);
      for (const payload of filtered) {
        items.push({
          memberId: payload.memberId,
          date: this.dateInput,
          description: this.description,
          withBible: payload.withBible,
          withVisitor: payload.withVisitor,
          completedChallenge: payload.completedChallenge,
          points: payload.points,
          presenceVisitor: payload.presenceVisitor,
        });
      }
      const payloadCall = {
        date: this.dateInput,
        presences: items,
      };

      this.loadingAction = true;
      http.post("promise/presences", payloadCall).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registros Inseridos com Sucesso!";
          this.snackbar.opened = true;
          this.loadingAction = false;
          this.closeDialog();
          this.loadingAction = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Inserir Registros. Tente Novamente!";
          this.snackbar.opened = true;
          this.loadingAction = false;
        }
      );
    },

    closeDialog() {
      this.name = "";
      this.id = "";
      this.description = "Teen Encontro";
      this.dataPayload.completedChallenge = false;
      this.dataPayload.presenceVisitor = true;
      this.dataPayload.withBible = false;
      this.dataPayload.withVisitor = false;
      this.dataPayload.points = 10;
      this.openDialog = false;
      this.getItems();
    },
  },
  mounted() {
    this.getItems();
    this.getLeaders();
  },
});
</script>
